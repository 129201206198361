import React from 'react';
import injectSheet from 'react-jss';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';

import i18next from '../config/i18next';
import Button from '../components/button';
import Translate from '../components/Translate';
import Layout from '../components/layout';
import InfoSection from '../components/infoSection';
import GetAppsSection from '../components/getAppsSection';
import AppleGoogleStores from '../components/appleGoogleStores';
import backgroundImage from '../data/images/index/home-hero.jpg';
import responsiveBackgroundImage from '../data/images/index/home-hero-mobile.jpg';
import readyToGetMovingImage from '../data/images/index/home-get-started.jpg';
import shopDevices from '../data/images/index/devices.png';
import iphoneMobileMyDay from '../data/images/index/screen-phone-myday.png';
import iphoneMobileCoach from '../data/images/index/screen-phone-coach.png';
import iphoneMobileChallenge from '../data/images/index/screen-phone-challenge.png';
import iphoneMobileActivity from '../data/images/index/screen-phone-activity.png';
import webChallenge from '../data/images/index/screen-challenge.png';
import webCoach from '../data/images/index/screen-coach.png';
import webDashBoard from '../data/images/index/screen-glance.png';
import webActivity from '../data/images/index/screen-activity.png';
import vivofitAppIcon from '../data/images/app-icon-vvfj.png';
import golfAppIcon from '../data/images/app-icon-golf.png';
import diveAppIcon from '../data/images/dive-app.png';
import connectIQAppIcon from '../data/images/connectiq-app.png';
import phoneSVG from '../data/images/SVG-files/icon-phone.svg';
import runnerSVG from '../data/images/SVG-files/icon-runner.svg';
import myDaySVG from '../data/images/SVG-files/icon-my-day.svg';
import { isChinaUser } from '../config/isChina';
import { isUserSignedIn } from '../config/isUserSignedIn';

const styles = {
    landingSection: {
        display: 'flex',
        width: '80%',
        padding: '5% 10% 0 10%',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    landingText: {
        justifyContent: 'space-between',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginRight: '20px',
        flex: '1 0 40%',
        '& > div:nth-of-type(1)': {
            fontSize: '4em',
            marginBottom: '30px',
            lineHeight: '65px',
            width: '65%',
            fontWeight: '500'
        },
        '& > div:nth-of-type(2)': {
            width: '50%',
            fontSize: '1.4em',
            fontWeight: '300'
        },
        height: '50vh'
    },
    appSection: {
        display: 'inline-flex',
        margin: '30px 0 30px 0'
    },
    appImageSection: {
        flex: '1 0 44%'
    },
    exploreSection: {
        flex: '0 1 100%',
        textAlign: 'center',
        top: '90vh',
        width: '70%',
        fontSize: '1.5em',
        fontWeight: '400',
        '& > div:first-child': {
            cursor: 'pointer',
            display: 'inline-block'
        },
        '& a': {
            display: 'block',
            marginTop: '30px'
        },
        '& p': {
            margin: 0
        }
    },
    downArrow: {
        border: 'solid black',
        borderWidth: '0 3px 3px 0',
        display: 'inline-block',
        padding: '3px',
        transform: 'rotate(45deg)'
    },
    iphoneMobileApp: {
        width: '270px',
        height: 'auto !important'
    },
    /* ----------------------------  'How It Works' section ------------------------------------ */
    howGCWorksSection: {
        height: '400px',
        display: 'flex',
        backgroundColor: '#555',
        flexWrap: 'wrap',
        justifyContent: 'center',
        color: '#fff',
        textAlign: 'center',
        fontSize: '24px',
        fontWeight: '300',
        padding: '20px 0 20px 0px',
        '& > div:first-child': {
            flexBasis: '100%',
            textAlign: 'center',
            fontWeight: 400,
            fontSize: '1.9em',
            padding: '70px 0 0 0'
        },
        '& > div:not(first-child)': {
            paddingRight: '20px',
            width: '200px',
            fontSize: '18px'
        },
        '& img': {
            height: '75px',
            width: '42px'
        },
        '& img:nth-of-type(1)': {
            width: '61px'
        }
    },
    /* -------------------- Shop for devices section--------------------------------------- */
    shopDevices: {
        background: '#555',
        color: '#fff',
        height: '630px',
        display: 'flex',
        flexDirection: 'column',
        '& img': {
            width: '100%',
            height: 'auto',
            maxHeight: '450px' // IE fix to render the image right
        }
    },
    shopDevicesContent: {
        padding: '20px 0% 0% 15%',
        width: '70%',
        display: 'inline-flex',
        justifyContent: 'space-between',
        '& > div:nth-of-type(1)': {
            width: '50%',
            display: 'inline-block',
            '& > div:nth-of-type(1)': {
                fontSize: '2.2em',
                fontWeight: '400',
                marginBottom: '20px',
                lineHeight: '35px'
            },
            '& > div:nth-of-type(2)': {
                fontSize: '1.5em',
                fontWeight: '300',
                color: '#d4d4d4'
            }
        },
        '& > div:nth-of-type(2)': {
            alignSelf: 'end'
        }
    },
    /* -------------------- Ready to Get Started section --------------------------------------- */
    readyToGetMoving: {
        height: '100vh',
        minHeight: '800px',
        backgroundImage: `url(${readyToGetMovingImage})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom'
    },
    readyToGetMovingContent: {
        padding: '5% 0% 0% 15%',
        width: '70%',
        '& > div:nth-of-type(1)': {
            width: '50%',
            marginBottom: '30px',
            '& > div:nth-of-type(1)': {
                fontSize: '2em',
                fontWeight: '500',
                marginBottom: '10px',
                lineHeight: '35px'
            },
            '& > div:nth-of-type(2)': {
                fontSize: '1.4em',
                fontWeight: '300'
            }
        }
    },
    /* -------------------- Mobile --------------------------------------- */
    '@media only screen and (max-width: 767px)': {
        exploreSection: {
            display: 'none'
        },
        appImageSection: {
            display: 'none'
        },
        landingSection: {
            width: '80%',
            padding: '25px 10% 0 10%',
            textAlign: 'center'
        },
        landingText: {
            alignItems: 'center',
            marginRight: '0px',
            '& > div:nth-of-type(1)': {
                fontSize: '2em',
                marginBottom: '30px',
                lineHeight: '40px',
                width: '100%'
            },
            '& > div:nth-of-type(2)': {
                width: '100%',
                fontSize: '1.2em',
                fontWeight: '300'
            }
        },
        appSection: {
            display: 'flex',
            flexDirection: 'column',
            '& > div': {
                margin: '0 0 20px 0',
                alignSelf: 'center'
            }
        },
        /* ----------------------------  'How It Works' section ------------------------------------ */
        howGCWorksSection: {
            height: '100vh',
            alignItems: 'center',
            padding: '20px 0 20px 0px',
            flexDirection: 'column',
            '& > div:first-child': {
                flexBasis: 'auto',
                padding: '0 0 35px 0',
                fontSize: '1.2em'
            },
            '& > div:not(first-child)': {
                width: '200px',
                padding: 0,
                marginBottom: '20px'
            }
        },
        /* -------------------- Shop for devices section--------------------------------------- */
        shopDevices: {
            height: '440px'
        },
        shopDevicesContent: {
            width: 'auto',
            textAlign: 'center',
            padding: '70px 40px',
            display: 'flex',
            flexDirection: 'column',
            '& > div:nth-of-type(1)': {
                width: 'auto',
                display: 'block',
                '& > div:nth-of-type(1)': {
                    fontSize: '1.3em'
                },
                '& > div:nth-of-type(2)': {
                    marginBottom: '30px',
                    fontSize: '1.1em'
                }
            },
            '& > div:nth-of-type(2)': {
                alignSelf: 'center'
            }
        },
        /* -------------------- Ready to Get Started section --------------------------------------- */
        readyToGetMoving: {
            backgroundImage: 'none',
            height: '300px'
        },
        readyToGetMovingContent: {
            padding: '60px 40px',
            width: 'auto',
            textAlign: 'center',
            '& > div:nth-of-type(1)': {
                width: 'auto'
            }
        }
    },
    '@media only screen and (max-width: 321px)': {
        howGCWorksSection: {
            '& > div:not(first-child)': {
                width: '200px',
                padding: 0,
                marginBottom: '0'
            }
        }
    }
};

class IndexPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            updateAndroidLink: false
        };
    }

    componentDidMount() {
        if (isUserSignedIn()) {
            window.location.replace('/modern/');
        }
        this.loadTitleTranslation();
        this.setState({ updateAndroidLink: isChinaUser() });
    }

    renderAppendedChildren = () => {
        const { classes } = this.props;
        const shopDevicesLink = this.state.updateAndroidLink
            ? 'https://www.garmin.com.cn/'
            : 'https://buy.garmin.com/en-US/US/into-sports/cIntoSports-p1.html';
        const golfApp = {
            url: this.state.updateAndroidLink
                ? 'https://www.garmin.com.cn/products/apps/garmingolfapp/'
                : 'https://buy.garmin.com/en-US/US/p/590068',
            image: golfAppIcon,
            title: 'Garmin Golf'
        };
        const vivofitApp = {
            url: this.state.updateAndroidLink
                ? 'https://www.garmin.com.cn/products/apps/garmin_jr_app/'
                : 'https://buy.garmin.com/en-US/US/p/568169',
            image: vivofitAppIcon,
            title: 'Garmin Jr.'
        };
        const connectQIApp = {
            url: this.state.updateAndroidLink
                ? 'https://www.garmin.com.cn/products/apps/connect-iq/'
                : 'https://buy.garmin.com/en-US/US/p/616398',
            image: connectIQAppIcon,
            title: 'Connect IQ Store'
        };
        const diveApp = {
            url: this.state.updateAndroidLink
                ? 'https://www.garmin.com.cn/products/apps/garmin-dive-app/'
                : 'https://buy.garmin.com/en-US/US/p/707742',
            image: diveAppIcon,
            title: 'Garmin Dive'
        };

        return (
            <aside>
                {/* -------------------- 'How it works' section --------------------------------------- */}
                <div id="exploreSection" className={classes.howGCWorksSection}>
                    <div>
                        {' '}
                        <Translate ns="prelogin_pages" content="how_it_works" />
                    </div>
                    <div>
                        <img src={phoneSVG} alt="" />
                        <p>
                            <Translate ns="prelogin_pages" content="install_garmin_connect_on_your_phone" />
                        </p>
                    </div>
                    <div>
                        <img src={runnerSVG} alt="" />
                        <p>
                            <Translate ns="prelogin_pages" content="get_active_with_your_garmin_device" />
                        </p>
                    </div>
                    <div>
                        <img src={myDaySVG} alt="" />
                        <p>
                            <Translate ns="prelogin_pages" content="view_your_progress_in_garmin_connect" />
                        </p>
                    </div>
                </div>

                {/* -------------------- App/Web showcases section --------------------------------------- */}
                <InfoSection
                    contentTitle={<Translate ns="prelogin_pages" content="your_day_at_a_glance" />}
                    contentList={[
                        <Translate ns="prelogin_pages" content="your_day_at_a_glance_content_1" />,
                        <Translate ns="prelogin_pages" content="your_day_at_a_glance_content_2" />
                    ]}
                    desktopImage={webDashBoard}
                    mobileImage={iphoneMobileMyDay}
                />
                <InfoSection
                    contentTitle={<Translate ns="prelogin_pages" content="beat_yesterday" />}
                    contentList={[<Translate ns="prelogin_pages" content="beat_yesterday_content" />]}
                    desktopImage={webCoach}
                    mobileImage={iphoneMobileCoach}
                >
                    <Button size="large">
                        <Link to="/features/coach/">
                            {<Translate ns="prelogin_pages" content="explore_garmin_coach" />}
                        </Link>
                    </Button>
                </InfoSection>

                <InfoSection
                    contentTitle={<Translate ns="prelogin_pages" content="track_your_activities" />}
                    contentList={[<Translate ns="prelogin_pages" content="track_your_activities_content" />]}
                    desktopImage={webActivity}
                    mobileImage={iphoneMobileActivity}
                />
                <InfoSection
                    contentTitle={<Translate ns="prelogin_pages" content="connect_with_friends" />}
                    contentList={[<Translate ns="prelogin_pages" content="connect_with_friends_content" />]}
                    desktopImage={webChallenge}
                    mobileImage={iphoneMobileChallenge}
                />
                {/* -------------------- Shop for devices section--------------------------------------- */}
                <div className={classes.shopDevices}>
                    <img src={shopDevices} alt="" />
                    <div className={classes.shopDevicesContent}>
                        <div>
                            <div>{<Translate ns="prelogin_pages" content="made_for_your_garmin_device" />}</div>
                            <div>{<Translate ns="prelogin_pages" content="made_for_your_garmin_device_content" />}</div>
                        </div>
                        <Button size="large">
                            <a href={shopDevicesLink}>{<Translate ns="prelogin_pages" content="shop_for_devices" />}</a>
                        </Button>
                    </div>
                </div>

                {/* -------------------- Ready to Get Started section --------------------------------------- */}
                <div className={classes.readyToGetMoving}>
                    <div className={classes.readyToGetMovingContent}>
                        <div>
                            <div>{<Translate ns="prelogin_pages" content="ready_to_get_moving" />}</div>
                            <div>{<Translate ns="prelogin_pages" content="ready_to_get_moving_content" />}</div>
                        </div>
                        <Button size="large">
                            <Link to="/start/">{<Translate ns="prelogin_pages" content="get_started" />}</Link>
                        </Button>
                    </div>
                </div>

                {/* -------------------- Garmin Connect apps section --------------------------------------- */}
                <GetAppsSection appList={[connectQIApp, diveApp, golfApp, vivofitApp]} />
            </aside>
        );
    };

    /*
     * The purpose of this function is to ensure that tranlations are loaded before we set the title in the head. React Helmet does not allow React
     * nodes for the title so we cannot use Translate component. Using i18next directly would just load the key as i18next hasn't loaded the the json files yet.
     * So we need to keep track of the title state and update it once i18next loads translations
     */
    loadTitleTranslation() {
        i18next.loadNamespaces('prelogin_pages', (err) => {
            if (err) {
                console.log(err[0]);
            }

            let text = i18next.t(`prelogin_pages:gc_online_community`, { skipInterpolation: true });
            this.setState({ title: text });
        });
    }

    render() {
        const { classes } = this.props;
        const title = this.state.title;

        return (
            <Layout
                responsiveBackgroundUrl={responsiveBackgroundImage}
                backgroundUrl={backgroundImage}
                appendChildren={this.renderAppendedChildren()}
            >
                {/* ------    Landing page + scroll pointer -------------------- */}
                <div className={classes.landingSection}>
                    <Helmet>
                        defer={false}
                        <title>Garmin Connect | {title}</title>
                        <meta property="og:title" content="Garmin Connect | Free Online Fitness Community" />{' '}
                        <meta
                            name="description"
                            content="Running, walking, cycling, swimming, skiing, triathlons – no matter how you move, you can record your active lifestyle on Garmin Connect. It’s the only online community created specifically for Garmin devices."
                        />
                        <meta property="og:url" content="https://connect.garmin.com/" />
                        <meta property="og:title" content="Garmin Connect" />
                        <meta
                            property="og:description"
                            content="Running, walking, cycling, swimming, skiing, triathlons – no matter how you move, you can record your active lifestyle on Garmin Connect. It’s the only online community created specifically for Garmin devices."
                        />
                    </Helmet>
                    <div className={classes.landingText}>
                        <div>
                            <Translate ns="prelogin_pages" content="fitness_at_your_fingers_header" />
                        </div>
                        <div>
                            <Translate ns="prelogin_pages" content="fitness_at_your_fingers_content" />
                        </div>
                        <div className={classes.appSection}>
                            <AppleGoogleStores />
                        </div>
                    </div>
                    <div className={classes.appImageSection}>
                        <img src={iphoneMobileMyDay} className={classes.iphoneMobileApp} alt="" />
                    </div>
                    <div className={classes.exploreSection}>
                        <div>
                            <AnchorLink href="#exploreSection">
                                <p>
                                    <Translate ns="prelogin_pages" content="explore_label" />
                                </p>
                                <div className={classes.downArrow} />
                            </AnchorLink>
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }
}

export default injectSheet(styles)(IndexPage);
